import { Directive, HostListener, Input } from '@angular/core';

@Directive({ selector: '[appPreventLossFormData]' })
export class PreventLossFormDataDirective {
  @Input() isDirty: boolean | null = false;

  @HostListener('window:beforeunload')
  beforeunload(): boolean {
    return !this.isDirty;
  }
}
