import { Pipe, PipeTransform } from '@angular/core';
import { LessonContentTypeEnum } from '@lms/shared/models';

@Pipe({
  name: 'formatLessonContentType',
})
export class FormatLessonContentTypePipe implements PipeTransform {
  transform(value: unknown): unknown {
    switch (value) {
      case LessonContentTypeEnum.TEXT:
        return 'text';
      case LessonContentTypeEnum.VIDEO:
        return 'video';
      case LessonContentTypeEnum.AUDIO:
        return 'audio';
      case LessonContentTypeEnum.MULTIMEDIA_EXTERNAL_URL:
        return 'multimedia';
      case LessonContentTypeEnum.MULTIMEDIA_ZIP_URL:
        return 'multimedia';
      case LessonContentTypeEnum.MULTIMEDIA_ZIP:
        return 'multimedia';
      case LessonContentTypeEnum.PDF:
        return 'pdf';
      case LessonContentTypeEnum.DOWNLOAD:
        return 'download';
      case LessonContentTypeEnum.QUIZ:
        return 'quiz';
      case LessonContentTypeEnum.EXAM:
        return 'exam';
    }
    return '';
  }
}
