<section class="tabs_box" #viewport>
  <div class="tabs" data-tabs #stripe>
    <ng-container *ngFor="let item of tabs">
      <ng-container *ngIf="!item.hidden">
        <a
          #tab
          class="tab"
          [class.active]="item === selectedTab"
          (click)="handleTabClick(item)"
          (keyup.enter)="handleTabClick(item)"
          tabindex="0"
          role="button"
        >
          {{ item.name }}
        </a>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="showArrows">
    <button *ngIf="!firstTab" (click)="prevTab()" (keyup.enter)="prevTab()" class="button circle small prev"><i class="icon-prev"></i>Previous tab</button>
    <button *ngIf="!lastTab" (click)="nextTab()" (keyup.enter)="nextTab()" class="button circle small next"><i class="icon-next"></i>Next tab</button>
  </ng-container>
</section>
