import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ellipsis-text',
  templateUrl: './ellipsis-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EllipsisTextComponent {
  @Input() text: string = '';
  @Input() limit: number = 250;

  @Output() resizeText: EventEmitter<any> = new EventEmitter<any>();

  expanded!: boolean;

  expand(val: boolean): void {
    this.expanded = val;
    this.resizeText.emit();
  }
}
