import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'routerLink, [routerLink]',
})
export class RouterLinkDirective {
  @Input() routerLink!: any;

  @HostListener('keyup.enter', ['$event.target']) onEnterPress(btn: HTMLElement): void {
    if (btn.tagName.toLowerCase() !== 'a') {
      this.router.navigateByUrl(this.router.createUrlTree(this.routerLink));
    }
  }

  constructor(private router: Router) {}
}
