import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
@Component({
  selector: 'app-sortable-col',
  templateUrl: './sortable-col.component.html',
  styleUrls: ['./sortable-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableColComponent {
  @Input()
  field: string = '';

  @Input()
  currentSort: string = '';
}
