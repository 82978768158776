import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';
import { CookieService } from 'ngx-cookie-service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[href]',
  standalone: true,
})
export class KlaviyoLinkTrackingDirective implements OnInit {
  private listOfDomains: string[] = [];
  private kxProfileId: string | null = null;
  constructor(
    private el: ElementRef,
    private cookieService: CookieService,
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
  ) {
    this.listOfDomains = this.removeCurrentDomainFromList(this.environment.klaviyoTrackingDomains);
  }

  ngOnInit(): void {
    this.kxProfileId = this.cookieService.get('cfi_klaviyo_kx');
    if (this.shouldAddParamsToLink(this.el.nativeElement.host) && this.kxProfileId) this.addKxToLinks();
  }

  private shouldAddParamsToLink(link: string): boolean {
    return this.listOfDomains.some(domain => link === domain);
  }

  private removeCurrentDomainFromList(domains: string[] | undefined): string[] {
    if (!domains) return [];

    const index = domains.indexOf(window.location.host);
    if (index !== -1) {
      domains.splice(index, 1);
    }
    return domains;
  }

  private addKxToLinks(): void {
    const href = this.el.nativeElement.getAttribute('href');
    const paramConnector = href.indexOf('?') === -1 ? '?' : '&';
    const newHref = href + paramConnector + '_kx=' + this.kxProfileId;

    this.el.nativeElement.setAttribute('href', newHref);
  }
}
