import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 20, trail: string = '...'): string {
    const result = value || '';

    return result.length > limit ? result.substring(0, limit) + trail : result;
  }
}
