import { Component, ChangeDetectionStrategy, Input, TemplateRef, ContentChild, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit {
  @Input() position: string | null = 'top';
  @Input() color: string | null = 'dark';
  @Input() size: string | null = null;
  @Input() icon: string | null = null;
  @Input() faIcon: string | null = null;
  @Input() isCentered: boolean = false;
  @Input() notHoverAble: boolean = false;

  @ContentChild('code', { static: false }) codeTemplateRef: TemplateRef<unknown> = {} as TemplateRef<unknown>;

  @HostBinding('class.not-icon') @Input() notIcon: boolean = false;

  isOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.notIcon === this.notIcon;
  }
}
