import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHost',
})
export class RemoveHostPipe implements PipeTransform {
  transform(url: string | undefined | null): string {
    if (!url) return '';
    try {
      const urlObj = new URL(url);
      return urlObj.pathname;
    } catch (error) {
      console.error('Invalid URL:', url);
      return url;
    }
  }
}
