import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { DndDirective } from './dnd.directive';
import { HasAccessDirective } from './has-access.directive';
import { HasErrorStateDirective } from './has-error-state.directive';
import { ImageLazyLoadDirective } from './image-lazy-load.directive';
import { ScrollTopDirective } from './scroll-top.directive';
import { ScrollToElementDirective } from './scroll-to-element.directive';
import { PreventLossFormDataDirective } from './prevent-loss-form-data.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { FallbackSrcDirective } from './fallback-src.directive';

const DIRECTIVES = [
  CopyClipboardDirective,
  DndDirective,
  HasAccessDirective,
  HasErrorStateDirective,
  ImageLazyLoadDirective,
  ScrollTopDirective,
  PreventLossFormDataDirective,
  ScrollToElementDirective,
  InfiniteScrollDirective,
  ClickOutsideDirective,
  FallbackSrcDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: DIRECTIVES,
  exports: DIRECTIVES,
})
export class SharedDirectivesModule {}
