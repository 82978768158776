import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';

@Component({
  selector: 'app-full-immersion-icon',
  templateUrl: './full-immersion-icon.component.html',
  styleUrls: ['./full-immersion-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullImmersionIconComponent {
  @Input() forceUnlocked = false;
  @Input() unfilledLocked = false;
  constructor(public currentUserStorage: CurrentUserStorage) {}
}
