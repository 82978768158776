import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'app-toggler',
  templateUrl: './toggler.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TogglerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TogglerComponent implements ControlValueAccessor {
  @Input() label: TogglerLabelOptions = {} as TogglerLabelOptions;
  val: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean): void {
    this.val = value;
    this.cdr.markForCheck();
  }

  updateValue(): void {
    this.val = !this.val;
    this.onChange(this.val);
    this.onTouched();
  }

  private onChange = (val: boolean): void => noop();

  private onTouched = (): void => noop();
}

export interface TogglerLabelOptions {
  true: string;
  false: string;
}
