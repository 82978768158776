import { Directive, HostListener } from '@angular/core';
import { DefineClickKeypressService } from './define-click-keypress.service';

@Directive({
  selector: '[appKeypressClickListener]',
  standalone: true,
})
export class KeypressClickListenerDirective {
  constructor(private click: DefineClickKeypressService) {}

  @HostListener('window:click')
  setIsClickTrue(): void {
    this.click.isClick = true;
  }

  @HostListener('window:keydown.tab')
  setIsClickFalse(): void {
    this.click.isClick = false;
  }
}
