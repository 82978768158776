<ng-container
  *ngIf="status !== BundleEnrollmentStatus.COMPLETED
  && status !== BundleEnrollmentStatus.PASSED
  && status !== BundleEnrollmentStatus.FAILED"
>
  <div [hidden]="!(percentAtBottom || percentAtRight)" [class.hide-complete]="hideComplete" [class.progress-short-view-right]="percentAtRight">
    <div class="progress_bar">
      <span [style.width.%]="progress"></span>
    </div>
    <div class="progress_label">{{progress}}% <span>complete</span></div>
  </div>

  <div [hidden]="percentAtBottom || percentAtRight" [class.progress-short-view]="!percentAtBottom">
    <div class="visible-tablet progress_info">{{progressInfo}}</div>
    <div class="progress_top">
      <div class="hidden-tablet progress_info">{{progressInfo}}</div>
      <div class="progress_label">{{progress}}%</div>
    </div>
    <div class="progress_bar">
      <span [style.width.%]="progress"></span>
    </div>
</div>
</ng-container>
