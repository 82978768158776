import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { NotificationModel, NotificationType } from '@lms/shared/models';

@Component({
  selector: 'app-inline-notification',
  animations: [trigger('fadeInOut', [transition(':leave', [animate('600ms', style({ height: '0' }))])])],
  templateUrl: 'inline-notification.component.html',
  styleUrls: ['inline-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineNotificationComponent {
  @Input() public notification: NotificationModel = {} as NotificationModel;
  @Input() public type: NotificationType = NotificationType.Warning;
  @Input() public small: boolean = false;
  @Input() public closable: boolean = true;
  @Input() public isAlert: boolean = true;

  @Output() public closeNotification: EventEmitter<unknown> = new EventEmitter<unknown>();

  @ContentChild('icon', { static: false }) iconTemplateRef: TemplateRef<unknown> = {} as TemplateRef<unknown>;
  @ContentChild('message', { static: false }) messageTemplateRef: TemplateRef<unknown> = {} as TemplateRef<unknown>;
  public notificationType = NotificationType;

  constructor(private cdr: ChangeDetectorRef) {}

  // force the slide up when the message is clicked
  @HostListener('click') onClick(): void {
    this.clearNotification();
  }

  clearNotification(): void {
    this.notification = {} as NotificationModel;
    this.cdr.markForCheck();
  }

  close(): void {
    this.closeNotification.emit();
  }
}
