import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique',
})
export class UniquePipe implements PipeTransform {
  transform<T>(array: T[], property: string): T[] {
    const newArray = array as any[];
    const unique = newArray.filter((value, index, arr) => {
      return arr.findIndex(innerValue => innerValue[property] === value[property]) === index;
    });

    return unique as T[];
  }
}
