import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { PricingPlanLinks, UserModel } from '@lms/shared/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-full-immersion-blocker-dialog',
  templateUrl: './full-immersion-blocker-dialog.component.html',
  styleUrls: ['./full-immersion-blocker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullImmersionBlockerDialogComponent implements OnInit {
  user: UserModel | null = null;
  url = '';
  isSubscriber = false;

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private currentUserStorage: CurrentUserStorage) {}

  ngOnInit(): void {
    this.user = this.currentUserStorage.getCurrentUser();
    this.buildUrl();

    this.isSubscriber = this.currentUserStorage.isUserSubscriber();
  }

  private buildUrl(): void {
    const isFullImmersion = this.currentUserStorage.isUserFullImmersion();
    const isSubscriber = this.currentUserStorage.isUserSubscriber();
    const hasCompanies = this.user?.companies?.length;

    if (isFullImmersion || hasCompanies) {
      return;
    }

    this.currentUserStorage.pricingPlanLinks$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((pricingPlanLink: PricingPlanLinks) => {
      this.url = isSubscriber
        ? '/user/edit/my-billing-and-orders#fi-upgrade'
        : pricingPlanLink.fullImmersion.slice(0, pricingPlanLink.fullImmersion.indexOf('&coupon='));
    });
  }
}
