<div class="card_box">
  <button aria-label="close" class="close-btn" mat-dialog-close>
    <app-svg-icon class="ico_close" icon="close"></app-svg-icon>
    Close
  </button>

  <div class="card_box_title">
    <app-full-immersion-icon [unfilledLocked]="true"></app-full-immersion-icon>
    <h3>Upgrade to Full-Immersion for Access</h3>
  </div>

  <p *ngIf="!isSubscriber">
    Join a network of finance professionals from over 170 countries who trust CFI to advance their careers. Gain real-world skills, access a
    wide range of finance courses, and learn from seasoned professionals with extensive industry experience.
  </p>
  <p>With Full-Immersion, you'll receive access to everything in the Self-Study plan, plus:</p>

  <div class="plus-item">
    <app-svg-icon class="checkmark" icon="checkmark"></app-svg-icon>
    <span>Course-integrated AI-chatbot</span>
  </div>
  <div class="plus-item">
    <app-svg-icon class="checkmark" icon="checkmark"></app-svg-icon>
    <span>Exclusive partner offers</span>
  </div>
  <div class="plus-item">
    <app-svg-icon class="checkmark" icon="checkmark"></app-svg-icon>
    <span>In-depth course content support and personalized career guidance</span>
  </div>

  <div class="buttons">
    <a [href]="url" class="button gold small" target="_blank" mat-dialog-close>
      <span class="wrapping-content">
        <span class="before">Upgrade to Full-Immersion</span>
        <span class="after">Upgrade to Full-Immersion</span>
      </span>
    </a>
    <a
      *ngIf="!isSubscriber"
      href="https://corporatefinanceinstitute.com/upgrade/"
      class="button outlined secondary small"
      target="_blank"
      mat-dialog-close
    >
      <span class="wrapping-content">
        <span class="before">Compare Plans</span>
        <span class="after">Compare Plans</span>
      </span>
    </a>
  </div>
</div>
