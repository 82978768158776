import { Pipe, PipeTransform } from '@angular/core';
import { PricingPlanEnum, PricingPlanModel } from '@lms/shared/models';
import { PricePipe } from './price.pipe';

export const ZERO_PRICE = '$0';

@Pipe({
  name: 'pricingPlanDiscountedPrice',
})
export class PricingPlanDiscountedPricePipe implements PipeTransform {
  constructor(protected pricePipe: PricePipe) {}
  public transform(pricingPlan: undefined | PricingPlanModel | null): string {
    if (!pricingPlan) {
      return ZERO_PRICE;
    }

    const price = pricingPlan.prices?.discounted?.totalPrice || pricingPlan.prices?.regular?.totalPrice;
    const amount = this.pricePipe.transform(price);
    return `${amount}`;
  }
}
