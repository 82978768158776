<app-tooltip
  *ngIf="nodeStatus === coursePlayerTreeChildStatus.OPTIONAL && !lockIconActive && !endOfPreview"
  position="left"
  [isCentered]="true"
  [notIcon]="true"
  [notHoverAble]="!showTooltip"
  >
  <ng-template #code>
    <app-svg-icon [icon]="icon" class="sidebar_icon sidebar_icon_complete" [ngClass]="iconClass"></app-svg-icon>
  </ng-template>
  This is an optional lesson
</app-tooltip>

<app-svg-icon
  *ngIf="nodeStatus !== coursePlayerTreeChildStatus.OPTIONAL && !lockIconActive || endOfPreview"
  [icon]="icon"
  class="sidebar_icon sidebar_icon_complete"
  [ngClass]="iconClass"
  ></app-svg-icon>

<app-tooltip *ngIf="lockIconActive && !endOfPreview"
  position="left"
  [isCentered]="true"
  [notIcon]="true">
  <ng-template #code>
    <app-svg-icon icon="course_locked" [ngClass]="iconClass"></app-svg-icon>
  </ng-template>
  <ng-container *ngIf="!isFreePreview">Complete previous<br />lesson to unlock</ng-container>
  <ng-container *ngIf="isFreePreview">
    <a
      (click)='clickTooltipLink()'
      target="_blank"
      >Get full access to unlock</a
    >
  </ng-container>
</app-tooltip>
