import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({ selector: '[appHasErrorState]' })
export class HasErrorStateDirective {
  controls: (null | AbstractControl)[] = [];

  @Input()
  set appHasErrorState(control: null | AbstractControl) {
    this.controls = [...this.controls, control];
  }

  @Input()
  set otherControls(controls: (null | AbstractControl)[]) {
    this.controls = [...this.controls, ...controls];
  }

  @HostBinding('class')
  get elementClass(): string {
    return this.controls.filter(control => (control?.touched && control?.errors) || control?.hasError('serverError')).length > 0
      ? 'has_error'
      : '';
  }
}
