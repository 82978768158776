import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CurrentUserStorage, ParameterStorage } from '@lms/shared/storages';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  constructor(
    private parameterStorage: ParameterStorage,
    private currentUserStorage: CurrentUserStorage,
  ) {}

  transform(value: string | null | undefined): string | null {
    if (value === null || value === undefined) {
      return null;
    }
    value = new Date(value).toLocaleString('en-US', { timeZone: this.currentUserStorage.getCurrentUser()?.timeZone });
    return new DatePipe('en-US').transform(value, this.parameterStorage.getDateFormat());
  }
}
