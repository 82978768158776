<span
  class="tooltip-wrapper support-tooltip"
  [ngClass]="{
    'not-icon': notIcon,
    'not-hover-able': notHoverAble,
    'open': isOpen
  }"
  (keyup.enter)="isOpen = !isOpen"
>
  <ng-container *ngIf="icon || faIcon">
    <app-svg-icon
      *ngIf="icon" icon="{{ icon }}"
      tabindex="0"
      role="button"
    ></app-svg-icon>
    <i
      *ngIf="faIcon"
      class="{{ faIcon }}"
      tabindex="0"
      role="button"
    ></i>
  </ng-container>
  <ng-container *ngIf="notIcon" [ngTemplateOutlet]="codeTemplateRef"></ng-container>
  <span class="tooltip" [class]="[position, color, size]" [class.is_centered]="isCentered">
    <ng-content></ng-content>
  </span>
</span>
