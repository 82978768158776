import { Pipe, PipeTransform } from '@angular/core';
import { CategoryService, DictionaryService } from '@lms/shared/services/api-services';
import {
  AdminCaptionLanguageModel,
  CategoryAutocompleteModel,
  DictionaryEnum,
  DropdownItem,
  EnumsDictionaryModel,
  LanguagesMapping,
} from '@lms/shared/models';
import { map, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'dictionary',
})
export class DictionaryPipe implements PipeTransform {
  constructor(
    private categoryService: CategoryService,
    private dictionaryService: DictionaryService,
  ) {}

  transform(dictionaryType: DictionaryEnum): Observable<Array<DropdownItem>> {
    if (DictionaryEnum.LANGUAGE === dictionaryType) {
      return of(
        LanguagesMapping.map((value: AdminCaptionLanguageModel) => ({
          value: value.languageCode,
          label: value.languageName,
        })),
      );
    }
    if (DictionaryEnum.COURSE_CATEGORY === dictionaryType) {
      return this.categoryService.autocompleteListing().pipe(
        map(res => {
          return res.data.categories.map((value: CategoryAutocompleteModel) => ({
            value: value.url,
            label: value.name,
          }));
        }),
      );
    }
    return this.dictionaryService
      .getEnumsDictionary()
      .pipe(map(this.getDictionaryMap(dictionaryType)), shareReplay({ bufferSize: 1, refCount: true }));
  }

  private getDictionaryMap(enumType: string): (data: EnumsDictionaryModel) => Array<DropdownItem> {
    return (data: EnumsDictionaryModel): DropdownItem[] => data[enumType as keyof EnumsDictionaryModel] || [];
  }
}
