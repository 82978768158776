import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idToName',
})
export class IdToNamePipe implements PipeTransform {
  transform(id: string, domains: any[], prop?: string): unknown {
    return domains?.find(el => (prop ? el[prop] : el.id) === +id)?.name || id;
  }
}
