<section class="tabs_box" #viewport>
  <div class="tabs" data-tabs #stripe>
    <ng-container *ngFor="let item of tabs">
      <ng-container *ngIf="item.link && !item.hidden">
        <a
          class="tab"
          #tab
          [routerLink]="[item.link]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: exactRouteActive}"
          (click)="calculateArrowStyles(); logTabClick($event, item.logName, item.eventName)"
          [attr.data-type]="item.name"
        >
          {{ item.name }}
        </a>
      </ng-container>
      <ng-container *ngIf="item.href">
        <a
          class="tab"
          #tab
          [href]="item.href"
          target="_blank"
        >
          {{ item.name }}
        </a>
      </ng-container>
      <ng-container *ngIf="item.twigUrl && !item.hidden">
        <a
          class="tab"
          #tab
          [href]="item.twigUrl"
        >
          {{ item.name }}
        </a>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="showArrows">
    <button *ngIf="!firstTab" (click)="prevTab()" class="button circle small prev"><i class="icon-prev"></i>Previous tab</button>
    <button *ngIf="!lastTab" (click)="nextTab()" class="button circle small next"><i class="icon-next"></i>Next tab</button>
  </ng-container>
</section>
