<button
  #btn
  [attr.type]="type"
  [ngClass]="state === BUTTON_STATE.LOADING ? 'is_loading' : ''"
  class="button {{ class }}"
  [disabled]="state === BUTTON_STATE.LOADING || state === BUTTON_STATE.DISABLED || isDisabled"
>
  <span class="wrapping-content">
    <span class="before">
      {{ title }}
      <ng-content></ng-content>
    </span>
    <span class="after">
      {{ title }}
      <ng-content></ng-content>
    </span>
  </span>
</button>
