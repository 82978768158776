<div class="card_box">
  <button aria-label="close" class="close-btn" mat-dialog-close>
    <app-svg-icon class="ico_close" icon="close"></app-svg-icon>
    Close
  </button>

  <div class="card_box_title">
    <app-svg-icon icon="course_locked_filled"></app-svg-icon>
    <h3>Upgrade to Unlock Community</h3>
  </div>

  <div class="items row">
    <div class="item col-12">
      <p class="card_box_text">
        Unlock your financial potential by joining CFI's community, exclusively available for Self-Study and Full-Immersion learners.
      </p>
      <p class="card_box_text">
        Engage directly with instructors and connect with a supportive network of peers who share your passion for financial success.
      </p>
      <p class="card_box_text">Upgrade now to get access.</p>
    </div>
    <div class="item col-12">
      <div class="buttons">
        <a [href]="'https://corporatefinanceinstitute.com/upgrade/'" class="button" target="_blank" mat-dialog-close>
          <span class="wrapping-content">
            <span class="before">Upgrade Now</span>
            <span class="after">Upgrade Now</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
