import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CurrentUserStorage, ParameterStorage } from '@lms/shared/storages';

@Pipe({
  name: 'formatDateDescriptive',
})
export class FormatDateDescriptivePipe implements PipeTransform {
  _MS_PER_DAY = 1000 * 60 * 60 * 24;

  constructor(
    private parameterStorage: ParameterStorage,
    private currentUserStorage: CurrentUserStorage,
  ) {}

  private static convertToNiceDate(daydiff: number): string {
    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) return '';

    if (0 == daydiff) {
      return 'Today';
    }
    if (1 == daydiff) {
      return 'Yesterday';
    }
    return daydiff + ' days ago';
  }

  transform(value: string | null): string | null {
    if (value === null) {
      return null;
    }
    value = new Date(value).toLocaleString('en-US', { timeZone: this.currentUserStorage.getCurrentUser()?.timeZone });
    const dif = this.dateDiffInDays(new Date(value), new Date());
    if (dif < 3) {
      return FormatDateDescriptivePipe.convertToNiceDate(dif);
    }
    return new DatePipe('en-US').transform(value, this.parameterStorage.getDateFormat());
  }

  dateDiffInDays(a: Date, b: Date): number {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }
}
