import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const DEFAULT_HEIGHT = 550;

@Component({
  selector: 'app-view-multimedia',
  templateUrl: './view-multimedia.component.html',
  styleUrls: ['./view-multimedia.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMultimediaComponent {
  @Input()
  fileUrl: string = '';
  @Input() set iframeHeight(value: number | null | undefined) {
    this._iframeHeight = value ? value : DEFAULT_HEIGHT;
  }
  _iframeHeight: number = DEFAULT_HEIGHT;

  constructor(private sanitizer: DomSanitizer) {}

  safeFileUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
  }
}
