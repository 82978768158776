import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Pipe({
  name: 'adminPanelDomain',
})
export class AdminPanelDomainPipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: Environment) {}

  transform(uri: string): URL {
    return new URL(uri, this.environment.adminPanelUrl);
  }
}
