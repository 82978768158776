import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@lms/shared/models';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img',
  standalone: true,
})
export class ImageCacheDirective implements OnInit {
  constructor(
    private el: ElementRef,
    @Inject(ENVIRONMENT_TOKEN) public environment: Environment,
  ) {}

  ngOnInit(): void {
    const imgElement = this.el.nativeElement as HTMLImageElement;
    const src = imgElement.src;
    imgElement.src = src + '?' + this.environment.appVersion;
  }
}
