import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserAchievementModel } from '@lms/shared/models';

@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievementComponent {
  @Input() achievement: UserAchievementModel = {} as UserAchievementModel;

  getBadgeUrl(achievement: UserAchievementModel): string {
    return `/assets/images/dashboard/achievements/badge-${achievement.slug}.svg`;
  }
}
