<ng-select
  appFocusVisible
  [items]="items"
  bindValue="value"
  bindLabel="label"
  [(ngModel)]="value"
  [placeholder]="placeholder"
  [clearable]="clearable"
  [disabled]="disabled"
>
</ng-select>
