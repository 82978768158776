import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToMegaBytes',
})
export class BytesToMegaBytesPipe implements PipeTransform {
  transform(value: number): number {
    return 0.000001 * value;
  }
}
