import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripDot',
})
/**
 * remove last dot from the sentence
 */
export class StripDotPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }
    return value.charAt(value.length - 1) == '.' ? value.substring(0, value.length - 1) : value;
  }
}
