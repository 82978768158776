import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkDirective } from './router-link.directive';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RouterLinkDirective],
  exports: [RouterLinkDirective],
  imports: [CommonModule, RouterModule],
})
export class RouterLinkModule {}
