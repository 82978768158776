import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './button/button.component';
import { SortableColComponent } from './sortable-column/sortable-col.component';
import { StatisticsTileComponent } from './statistics-tile/statistics-tile.component';
import { FormErrorComponent } from './form-errors/form-error.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { DeleteEntityComponent } from './delete-entity/delete-entity.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { RadioButtonSwitchComponent } from './radio-button-switch/radio-button-switch.component';
import { HeaderComponent } from './header/header.component';
import { EllipsisTextComponent } from './ellipsis-text/ellipsis-text.component';
import { SharedPipesModule } from '@lms/shared/pipes';
import { DropdownComponent } from './dropdown/dropdown.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { BundleIconDefaultComponent } from './bundle-icon-default/bundle-icon-default.component';
import { CourseProgressComponent } from './course-progress/course-progress.component';
import { ViewWysiwygComponent } from './view-wysiwyg/view-wysiwyg.component';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ViewMultimediaComponent } from './view-multimedia/view-multimedia.component';
import { RawSelectComponent } from './raw-select/raw-select.component';
import { InlineNotificationComponent } from './inline-notification/inline-notification.component';
import { TogglerComponent } from './toggler/toggler.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatepickerRangeComponent } from './datipicker-range/datepicker-range.component';
import { StarRatingSelectComponent } from './star-rating/star-rating-select/star-rating-select.component';
import { CourseTreeNodeStatusComponent } from './course-tree-node-status/course-tree-node-status.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AchievementComponent } from './achievement/achievement.component';
import { IconExpandableComponent } from './icon-expandable/icon-expandable.component';
import { LessonContentTypeIconComponent } from './lesson-content-type-icon/lesson-content-type-icon.component';
import { TabsRoutedComponent } from './tabs-routed/tabs-routed.component';
import { MatIconModule } from '@angular/material/icon';
import { DatepickerComponent } from './datipicker/datepicker.component';
import { FullImmersionBadgeComponent } from './full-immersion-badge/full-immersion-badge.component';
import { FullImmersionIconComponent } from './full-immersion-icon/full-immersion-icon.component';
import { UpcomingWarningComponent } from './upcoming-warning/upcoming-warning.component';
import { TabsSimpleComponent } from './tabs-simple/tabs-simple.component';
import { FullImmersionBlockerDialogComponent } from './full-immersion-blocker-dialog/full-immersion-blocker-dialog.component';
import { UnlockCommunityDialogComponent } from './unlock-community-dialog/unlock-community-dialog.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { InsightBoxComponent } from './insight-box/insight-box.component';
import { UserStoryCardComponent } from './user-story-card/user-story-card.component';
import { FocusVisibleDirective, ImageCacheDirective, KlaviyoLinkTrackingDirective } from '@lms/shared/directives';
import { UnlockCaseStudyChallengesDialogComponent } from './unlock-case-study-challenges-dialog/unlock-case-study-challenges-dialog.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'long', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'long' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

const COMPONENTS = [
  AchievementComponent,
  ButtonComponent,
  RadioButtonSwitchComponent,
  LoadingButtonComponent,
  SortableColComponent,
  StatisticsTileComponent,
  StarRatingComponent,
  FormErrorComponent,
  DeleteEntityComponent,
  HeaderComponent,
  EllipsisTextComponent,
  DropdownComponent,
  SvgIconComponent,
  BundleIconDefaultComponent,
  CourseProgressComponent,
  ViewWysiwygComponent,
  DropdownSelectComponent,
  ViewMultimediaComponent,
  RawSelectComponent,
  InlineNotificationComponent,
  TogglerComponent,
  DatepickerRangeComponent,
  DatepickerComponent,
  StarRatingSelectComponent,
  CourseTreeNodeStatusComponent,
  IconExpandableComponent,
  LessonContentTypeIconComponent,
  TabsRoutedComponent,
  FullImmersionIconComponent,
  FullImmersionBadgeComponent,
  UpcomingWarningComponent,
  TabsSimpleComponent,
  FullImmersionBlockerDialogComponent,
  UnlockCommunityDialogComponent,
  UnlockCaseStudyChallengesDialogComponent,
  TooltipComponent,
  InsightBoxComponent,
  UserStoryCardComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedPipesModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    ReactiveFormsModule,
    FocusVisibleDirective,
    ImageCacheDirective,
    KlaviyoLinkTrackingDirective,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, ImageCacheDirective, KlaviyoLinkTrackingDirective],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class SharedUiUiComponentsModule {}
