import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CustomBreakpointNames, LayoutService } from '@lms/shared/services/app-services';
import { CurrentUserStorage } from '@lms/shared/storages';
import { takeUntil } from 'rxjs/operators';
import { DestroyableDirective } from '../destroyable/destroyable.directive';

@Component({
  selector: 'app-full-immersion-badge',
  templateUrl: './full-immersion-badge.component.html',
  styleUrls: ['./full-immersion-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullImmersionBadgeComponent extends DestroyableDirective implements OnInit {
  @Input() text = 'Full-Immersion Exclusive';
  @Input() upgradeLink = 'https://corporatefinanceinstitute.com/upgrade/';

  isTablet = false;
  isFullImmersion = false;
  isSubscriber = false;

  constructor(
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef,
    public currentUser: CurrentUserStorage,
  ) {
    super();
    this.isFullImmersion = this.currentUser.isUserFullImmersion();
    this.isSubscriber = this.currentUser.isUserSubscriber();
  }
  ngOnInit(): void {
    this.layoutService
      .subscribeToLayoutChanges()
      .pipe(takeUntil(this._destroy$$))
      .subscribe(data => {
        this.isTablet = data.includes(CustomBreakpointNames.tablet);
        this.cdr.markForCheck();
      });
  }
}
