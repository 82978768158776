import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertMinutesToDaysHoursMinutes',
  standalone: true,
})
export class ConvertMinutesToDaysHoursMinutesPipe implements PipeTransform {
  transform(minutes: string, ...args: unknown[]): unknown {
    const seconds = parseInt(minutes) * 60;
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);

    const dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : '';
    const hDisplay = h > 0 ? h + ' h ' : '';
    const mDisplay = m > 0 ? m + ' min' : d == 0 && h == 0 && m == 0 ? 'less than 1 min' : '';
    return dDisplay + hDisplay + mDisplay;
  }
}
