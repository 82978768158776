<label for="toggled_element" class="switcher">
  <input
    appFocusVisible
    id="toggled_element"
    type="checkbox"
    name="status"
    [(ngModel)]="val"
    (click)="updateValue()"
    aria-label="option"
  />
  <span>{{ val ? label.true : label.false }}</span>
</label>
