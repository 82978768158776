<div class="star-rating">
  <div *ngFor="let star of starList; let idx = index"
    (click)="selectValue(star)"
    class="empty-star"
    [class.full-star]="+idx < +value"
    (keyup.enter)="selectValue(star)"
    tabindex="0"
    role="button"
    >
    <app-svg-icon icon="star"></app-svg-icon>
  </div>
</div>
