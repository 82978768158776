<a class="sortable" [routerLink]="[]"
   [queryParams]="{
     sort: currentSort === field + '_asc' ?  field + '_desc' : field + '_asc',
     pageNumber: 1
   }"
   queryParamsHandling="merge"
  >
  <div class="sort_label">
    <ng-content></ng-content>
  </div>

  <app-svg-icon icon="dd_expander" class="arrow expanded" *ngIf="currentSort === field + '_asc'"></app-svg-icon>
  <app-svg-icon icon="dd_expander" class="arrow" *ngIf="currentSort === field + '_desc'"></app-svg-icon>
  <i class="arrow fas fa-sort" *ngIf="!currentSort.includes(field)"></i>
</a>
