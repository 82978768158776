import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { LayoutService } from '@lms/shared/services/app-services';
import { TabItem } from '@lms/shared/models';
import { BaseTabDirective } from '../base-tab/base-tab.directive';

@Component({
  selector: 'app-tabs-simple',
  templateUrl: './tabs-simple.component.html',
  styleUrls: ['./tabs-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsSimpleComponent extends BaseTabDirective<TabItem> implements OnInit {
  @Output() selectTab: EventEmitter<any> = new EventEmitter<any>();
  @Input() defaultSelectedTab: TabItem | undefined;

  public selectedTab: TabItem | undefined;

  constructor(
    public cdr: ChangeDetectorRef,
    public renderer: Renderer2,
    public layout: LayoutService,
  ) {
    super(cdr, renderer, layout);
  }

  ngOnInit() {
    this.selectAndEmitTab(this.defaultSelectedTab || this.tabs[0]);
  }

  public handleTabClick(tabItem: TabItem): void {
    this.calculateArrowStyles();

    this.selectAndEmitTab(tabItem);
  }

  private selectAndEmitTab(tabItem: TabItem): void {
    this.selectedTab = tabItem;
    this.selectTab.emit(tabItem);
  }
}
