import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeHoursMins',
  standalone: true,
})
export class TimeHoursMinsPipe implements PipeTransform {
  transform(value: number, type: 'seconds' | 'minutes', minutesWithBlank?: boolean): string {
    const blank = minutesWithBlank ? ' ' : '';
    if (!value || value < 0) return '0' + blank + 'min';
    const hoursDivider = type === 'seconds' ? 3600 : 60;
    const hours = Math.floor(value / hoursDivider);
    if (hours === 0) {
      const minutes = type === 'seconds' ? Math.round(value / 60) : value;
      return `${minutes + blank}min`;
    }
    const minutes = type === 'seconds' ? Math.round((value % hoursDivider) / 60) : Math.round(value % hoursDivider);
    return `${hours}h` + (minutes > 0 ? ` ${minutes}min` : '');
  }
}
