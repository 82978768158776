import { Directive, HostBinding, HostListener } from '@angular/core';
import { DefineClickKeypressService } from './keyboard-or-click/define-click-keypress.service';

@Directive({
  selector: '[appFocusVisible]',
  standalone: true,
})
export class FocusVisibleDirective {
  @HostBinding('class') focusVisible = '';

  constructor(private click: DefineClickKeypressService) {}

  @HostListener('focus')
  setFocusVisible() {
    if (!this.click.isClick) {
      this.focusVisible = 'focus-visible';
    }
  }

  @HostListener('blur')
  removeFocusVisible() {
    this.focusVisible = '';
  }
}
