import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTimeLength',
})
export class SecondsToTimeLength implements PipeTransform {
  transform(value?: number): string {
    if (!value) {
      return '';
    }

    if (value < 60) {
      const seconds = this.roundToNearestMultipleOfFive(value);
      return `${seconds}sec`;
    } else if (value < 60 * 60) {
      if (value < 300 && value >= 150) {
        return '5min';
      }

      const date = new Date(value * 1000);
      const minutes = this.roundToNearestMultipleOfFive(date.getUTCMinutes());

      if (minutes === 0) {
        return '60sec';
      }

      if (minutes === 60) {
        return '1h';
      }

      return `${minutes}min`;
    } else {
      const hours = Math.floor(value / (60 * 60));
      const date = new Date(value * 1000);
      const minutes = this.roundToNearestMultipleOfFive(date.getUTCMinutes());

      if (minutes === 0) {
        return `${hours}h`;
      }

      if (minutes === 60) {
        return `${hours + 1}h`;
      }

      return `${hours}h ${minutes}min`;
    }
  }

  private roundToNearestMultipleOfFive(value: number): number {
    return Math.round(value / 5) * 5;
  }
}
