<div
  [@fadeInOut]
  class="notification_holder"
  [ngClass]="{
    fail: type === notificationType.Error,
    success: type === notificationType.Success,
    warning: type === notificationType.Warning,
    info: type === notificationType.Info,
    small: small,
    alert: isAlert,
  }"
>
  <span class="notification_icon">
    <ng-container *ngIf="notification.type === notificationType.Success"><i class="fas fa-check-circle"></i></ng-container>
    <ng-container *ngIf="notification.type === notificationType.Error"><i class="fas fa-exclamation-triangle"></i></ng-container>
    <ng-container [ngTemplateOutlet]="iconTemplateRef"></ng-container>
  </span>

  <span class="notification_text">
    {{ notification.message }}
    <ng-container [ngTemplateOutlet]="messageTemplateRef"></ng-container>
  </span>

  <button aria-label="close" *ngIf="closable" (click)="close()" class="btn-close">
    <app-svg-icon
      icon="close"
      class="close_icon"
      [ngClass]="{
        fail: type === notificationType.Error,
        success: type === notificationType.Success,
        warning: type === notificationType.Warning,
        info: type === notificationType.Info,
      }"
    ></app-svg-icon
    >Close
  </button>
</div>
