import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BundleEnrollmentStatusEnum } from '@lms/shared/models';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html',
  styleUrls: ['./course-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseProgressComponent {
  @Input() progress: number | string | undefined | null = 0;
  @Input() status: BundleEnrollmentStatusEnum | undefined | null;
  @Input() percentAtBottom: boolean = false;
  @Input() percentAtRight: boolean = false;
  @Input() hideComplete: boolean = false;
  @Input() progressInfo: string = '';

  BundleEnrollmentStatus = BundleEnrollmentStatusEnum;
}
