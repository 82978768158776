import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentUserStorage } from '@lms/shared/storages';
import { UserModel } from '@lms/shared/models';

@Component({
  selector: 'app-unlock-case-study-challenges-dialog',
  templateUrl: './unlock-case-study-challenges-dialog.component.html',
  styleUrls: ['./unlock-case-study-challenges-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnlockCaseStudyChallengesDialogComponent implements OnInit {
  user: UserModel | null = null;
  constructor(private currentUserStorage: CurrentUserStorage) {}

  ngOnInit(): void {
    this.user = this.currentUserStorage.getCurrentUser();
  }
}
