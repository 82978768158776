<span class="radiobox" *ngFor="let option of options; let idx = index">
  <label
    for="{{option.id + option.text.trim()}}"
    (click)="writeValue(option)"
    [ngClass]="{'button': !isTablet, 'outlined': !isTablet, 'secondary': !isTablet, 'semi-small': !isTablet}"
    [class.active]="option === val"
    (keyup.enter)="writeValue(option)"
    tabindex="0"
    role="button"
  >
    <input
      appFocusVisible
      type="radio"
      tabindex="-1"
      name='{{option.questionId}}'
      id='{{option.id + option.text.trim()}}'
      attr.aria-label='{{option.id + option.text.trim()}}'
    />
    <span class="checkmark" aria-hidden="true"></span>
    {{ option.text }}
  </label>
</span>
