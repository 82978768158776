<div class="user-story-card" *ngIf="userStoryData">
  <app-svg-icon class="user-story-card__blockquote" [icon]="'blockquote'"></app-svg-icon>
  <div class="user-story-card__title">
    {{userStoryData.title}}
  </div>
  <div class="user-story-card__text">
    {{userStoryData.content}}
  </div>
  <div class="user-story-card__person-info-wrapper">
    <div class="user-story-card__image">
      <img [src]="'/assets/user-profile-images/' + userStoryData.image" alt="{{userStoryData.fullName}}">
    </div>
    <div class="user-story-card__person-info">
      <div class="user-story-card__name">{{userStoryData.fullName}}</div>
      <div class="user-story-card__job-position">{{userStoryData.jobPosition}}</div>
      <a [attr.href]="userStoryData.linkOnMore" class="button tertiary link">
        Read the story
        <app-svg-icon icon="thin_arrow" class="icon_arrow"></app-svg-icon>
      </a>
    </div>
  </div>
</div>
