<a
  *ngIf="(currentUser.hasAccessToPremiumTools$ | async) === false"
  (click)="openDialog()"
  target="_blank"
  class="button gold"
  [class.small]="isSmall"
>
  <span class="wrapping-content">
    <span class="before">Upgrade to Full-Immersion</span>
    <span class="after">Upgrade to Full-Immersion</span>
  </span>
</a>
